<template>
  <div>
    <Table
      label="CMS"
      :actions="actions"
      :items="$store.getters['cms/tableData']"
      :fields="$store.getters['cms/tableFields']"
      :loading="$store.state.cms.loadingTable"
      :filter="$store.state.cms.tableFilter"
      :sort="$store.state.cms.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.cms.tableKeyword"
      :pagination="$store.state.cms.tablePagination"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
    <!-- Delete Post modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Post"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deletePost"
    >
      Are you sure want to delete this cms?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      deleteModalVisible: false,
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view',
        'update',
        'create', {
          type: 'other',
          children: [
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'Post'),
              },
            },
          ],
        }],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('cms/getTableData')
    },
    filterData(val) {
      this.$store.commit('cms/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('cms/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('cms/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('cms/SET_TABLE_KEYWORD', val)
    },
    changeFields(val) {
      this.$store.commit('cms/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('cms/RESET_TABLE_FIELDS')
    },
    deletePost() {
      this.$store.dispatch('cms/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The CMS has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
  },
}
</script>
